.auth-loader {
    position: fixed;
    z-index: 1100;
    width: 100%;
    overflow: hidden;
    height: 100%;
    background: #666666;
    opacity: 0.6;
}
.app-loader {
  width: 100%;
  height: 75vh;
}
.loader {
  background-color: transparent !important;
}
.loader-container {
    position: fixed;
    z-index: 1101;
    width: 100%;
    height: 100%;
}
.lds-ripple {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    margin: 0 auto;
    border-radius: 100px;
    animation: ripple .9s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 175, 238, 0.05)
  }
  100% {
    box-shadow: 0 0 0 40px rgba(0, 175, 238, 0.05)
  }
}
// .lds-ripple {
//     display: inline-block;
//     position: relative;
//     width: 5rem;
//     height: 5rem;
//     background-color: #ffffff;
    
//     border-radius: 50%;
// }
// .lds-ripple div {
//     position: absolute;
//     border: 0.25rem solid theme-color("primary");
//     opacity: 1;
//     border-radius: 50%;
//     animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
// }
// .lds-ripple div:nth-child(2) {
//     animation-delay: -0.5s;
// }
// @keyframes lds-ripple {
//     0% {
//         top: 2.25rem;
//         left: 2.25rem;
//         width: 0;
//         height: 0;
//         opacity: 1;
//     }
//     100% {
//         top: 0px;
//         left: 0px;
//         width: 4.5rem;
//         height: 4.5rem;
//         opacity: 0;
//     }
// }

//CUSTOM
.loader{
    &.h-100{
        position: fixed;
        width: 100%;
        height: 100%;
        background: white;
        top: 0;
        z-index: 999;
    }
}