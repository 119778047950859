html {
    margin: 0;
    padding: 0;
    min-width: 100%;
    height: 100%;
}
body {
    font-family: 'Poppins' !important;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
}

.mh_100vh {
    min-height: 100vh;
}
.minh_inherit {
    min-height: inherit;
}
.height_inherit {
    height: inherit;
}
// @media only screen and (min-width: 1000px){
//     .container{
//         max-width: 71.25rem !important;
//     }
// }