    .react-calendar__month-view__weekdays {
      justify-content: center !important;
    }
  
    .react-calendar__month-view__weekdays__weekday {
      flex-basis: 13.2857% !important;
      max-width: 13.2857% !important;
      margin: 1px !important
    }
  
    .react-calendar__month-view__weekdays__weekday abbr {
      text-decoration: none !important;
    }
  
    .react-calendar__tile {
      flex-basis: 13% !important;
      max-width: 13% !important;
      padding: 0.75em 0.1em !important;
      margin: 2px 1.2px !important;
      background: #ffffff !important;
      border-radius: 5px !important;
    }
  
    .react-calendar__tile:disabled {
      background-color: #f0f0f0 !important;
    }
  
    .react-calendar__month-view__days {
      justify-content: center !important;
    }
  
    .react-calendar__tile--active {
      color: #ffffff !important;
    }
  
    .react-calendar__tile--range {
      background-color: #d5e1ff !important;
      color: #006edc !important;
    }
  
    .react-calendar__tile--rangeStart {
      background-color: #006edc !important;
      color: #ffffff !important;
    }
  
    .react-calendar__tile--rangeEnd {
      background-color: #006edc !important;
      color: #ffffff !important;
    }
  
    .react-calendar__tile:enabled:hover.react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: #006edc !important;
      color: #ffffff !important;
      font-weight: 700;
    }
  
    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #d5e1ff !important;
    }
  
  
    .react-calendar__navigation {
      padding: 5px 7px;
      margin: 0px 0px 10px 0px;
      display: flex;
      justify-content: space-between;
    }