@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ybrs7z');
  src:  url('fonts/icomoon.eot?ybrs7z#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ybrs7z') format('truetype'),
    url('fonts/icomoon.woff?ybrs7z') format('woff'),
    url('fonts/icomoon.svg?ybrs7z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel:before {
  content: "\e916";
}
.icon-download-12:before {
  content: "\e915";
}
.icon-right-arrow:before {
  content: "\e914";
}
.icon-up-arrow:before {
  content: "\e913";
}
.icon-printer:before {
  content: "\e900";
}
.icon-download:before {
  content: "\e90c";
}
.icon-list:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e910";
}
.icon-filter:before {
  content: "\e912";
}
.icon-close:before {
  content: "\e90f";
}
.icon-tick:before {
  content: "\e90a";
}
.icon-eye:before {
  content: "\e9012";
}
.icon-plus:before {
  content: "\e90b";
}
.icon-doc-color:before {
  content: "\e90d";
}
.icon-premium:before {
  content: "\e90e";
}
.icon-sort-by:before {
  content: "\e909";
}
.icon-success:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e901";
}
.icon-settings:before {
  content: "\e902";
}
.icon-profile:before {
  content: "\e903";
}
.icon-sales:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e905";
}
.icon-doc:before {
  content: "\e906";
}
.icon-dashboard:before {
  content: "\e907";
}
