.etub {
}

.BoxShadowBOx {
  position: absolute;
  top: 60px;
  background: #dddddd;
  width: 100%;
  height: 90%;
  left: 0;
  right: 0;
  border-radius: 0 0 20px 20px;
  filter: drop-shadow(9px 9px 16px rgba(0, 0, 0, 0.25));
  z-index: 0;
  max-width: 100%;
  margin: 0 auto;
}
.LettersBox {
  position: relative;
  max-width: 830px;
}
.etub .letters {
  border-radius: 20px;
  display: flex;
  background-color: #fff;
  padding: 20px;
  height: 470px;
  box-shadow: inset 0px -5px 0px 0px #bfbfbf;
  flex-wrap: wrap;
  justify-content: space-between;
}
.etub .letters div:nth-child(27) {
  margin-left: auto;
}
.etub .letters div:nth-child(26) {
  margin-left: auto;
}
.etub .letters div:nth-child(25) {
  margin-left: auto;
}
.etub .letters div:nth-child(24) {
  margin-left: auto;
  flex: 0 0 18%;
}
.etub .letters div:nth-child(23) {
  margin-left: auto;
}

.translate-middle {
  left: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  text-align: center;
}
.dragged-image.translate-middle img {
  width: 100%;
  max-width: 180px;
}
.etub .tubicon img {
  /* margin-left: 11px; */
  width: 81px;
}
.etub .letter {
  width: 50px;
  height: 50px;
  /* border: 1px solid #ccc; */
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 5px;
}

.etub .letter.drag-over {
  border: 2px solid #f00;
}

.etub .image-container {
  width: 90%;
  height: 306px;
  overflow: hidden;
  border-radius: 10px;
  margin-left: 20px;
  cursor: pointer;
  box-shadow: -2px -3px 2px 3px #111111, -3px 0px 1px 0px #6a6a6a,
    4px 2px 4px 4px #a8a8a8, 0px 1px 3px -3px #c2c2c2 inset;
}
.etub .image-container02 {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  margin-left: 0;
  cursor: pointer;
  height: 100%;
}

.etub .dragged-image {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
.dragged-image img {
  width: 100%;
}
.etub .game {
  text-align: center;
  padding-top: 20px;
}
.gifimage {
  width: 200px;
  height: 200px;
}
p.img_Name {
  width: 40%;
  font-size: 20px;
  margin-top: 10px;
  text-transform: uppercase;
  color: #000;
  background: white;
  padding: 6px 0;
  border-radius: 50px;
}
p.img_Name_lowercase {
  width: 40%;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 10px;
  text-transform: lowercase;
  color: #000;
  background: white;
  padding: 6px 0;
  border-radius: 50px;
}
.gifimage img {
  width: 100%;
  height: 100%;
}
.etub .drap_img {
  width: 100%;
  height: 300px;
}
.revealbutton {
  border-radius: 12px;
  background: #f15a29;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
  color: "#fff" !important;
  border: none !important;
  padding: 8px 0;
}
.doneOrQuitebutton {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
  border: none !important;
}

.reveal-alphabets img {
  opacity: 1;
}

.custom-btn {
  padding: 10px 0px;
}
.alphabet,
.reveal-alphabets {
  height: 100px;
  flex: 0 0 13%;
  text-align: center;
}
.alphabet img,
.reveal-alphabets img {
  /* width: 100%; */
  height: 100%;
  /* object-fit: contain; */
}
img.bg-alphabet {
  object-fit: cover;
}
.avtar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.bg-alphabet {
  position: absolute;
  z-index: 99;
  left: 0;
  height: 100px;
  top: 0;
  opacity: 0;
}
.tubprofile {
  border: 2px solid #8b8b8b;
  margin-top: 8px;
  border-radius: 13px;
  padding: 4px;
}
.tubprofile p {
  font-weight: 500;
}
.reveal_outline {
  /* border: 2px solid #8b8b8b; */
  width: 80%;
  display: flex;
  height: 274px;
  justify-content: center;
  align-items: center;
  box-shadow: -2px -3px 2px 3px #111111, -3px 0px 1px 0px #6a6a6a,
    4px 2px 4px 4px #a8a8a8, 0px 1px 3px -3px #c2c2c2 inset;
}
.review_images {
  width: 80%;
  display: flex;
  height: 300px;
  /* border: 2px solid #9f9f9f; */
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* box-shadow: 0px 0px 7px 8px #4d4d4d; */
  box-shadow: -2px -3px 2px 3px #111111, -3px 0px 1px 0px #6a6a6a,
    4px 2px 4px 4px #a8a8a8, 0px 1px 3px -3px #c2c2c2 inset;
}
.imageTitle {
  width: 80%;
  margin: 0 auto;
}
@keyframes animation {
  from {
    transform: rotate3d(0, 0, 0, 0);
    opacity: 1;
  }
  to {
    transform: rotate3d(13, 49, 2, 90deg);
    opacity: 0;
  }
}
@keyframes animation-reverse {
  from {
    transform: rotate3d(13, 49, 2, 90deg);
    opacity: 0;
  }
  to {
    transform: rotate3d(0, 0, 0, 0);
    opacity: 1;
  }
}
